import { useState, ReactChild, ReactChildren } from "react";
import { css } from "@emotion/react";
import { colors } from "@mytutor/shared-react-web-components/lib/theme";

const faqStyle = (expandFAQs: boolean) => css`
  .question {
    width: 100%;
    padding-right: 7%;
    margin: 24px 0;
    font-size: 27px;
    line-height: 26px;
    font-weight: 500;
    color: ${expandFAQs ? colors.primary7 : colors.neutral8};
    cursor: pointer;
    position: relative;

    :hover {
      color: ${colors.primary7};
    }

    ::after {
      content: ${expandFAQs
        ? "url(/landing/chevron-vertical-primary7.svg)"
        : "url(/landing/chevron-vertical-neutral8.svg)"};
      position: absolute;
      top: ${expandFAQs ? "3px" : "-1px"};
      left: 95%;
      transition-property: transform;
      transform: ${expandFAQs ? "rotate(-180deg)" : ""};
      transition-duration: 0.2s;
    }
  }

  .answer {
    ${expandFAQs ? "" : "display: none"};
    margin: 24px 0;
    font-size: 16px;
    line-height: 1.72rem;
    font-weight: 400;

    ol {
      padding-left: 15px;

      li {
        padding: 5px 0 5px 5px;
      }
    }
  }
`;

type Props = {
  question: string;
  children: ReactChildren | ReactChild;
  open?: boolean;
};

const FAQs = ({ question, children, open = false }: Props) => {
  const [expandFAQs, setExpandFAQs] = useState(open);

  return (
    <li css={faqStyle(expandFAQs)}>
      <h4
        className="question"
        onClick={() => {
          setExpandFAQs(!expandFAQs);
        }}
      >
        {question}
      </h4>
      <span className="answer">{children}</span>
    </li>
  );
};

export default FAQs;
